import React from "react"
import SocialShareList from './social-share-list';

interface Props {
  title : string
  social_title?: string
  description?: string
  image?: string
  twitter?: boolean
  facebook?: boolean
  linkedin?: boolean
  weibo?: boolean
  weiboImageLink?: string
  wechat?: boolean
  wechatImageLink?: string
}

const ShareBox = ({ title, social_title, description, twitter = false, facebook = false, linkedin = false, weibo = false, weiboImageLink = '', wechat = false, wechatImageLink = '' }: Props) => (
  <div className="share-box">
        <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
        <div className="share-box__divider"/>
        <SocialShareList title={ social_title } description={ description } twitter={ twitter } facebook={ facebook } linkedin={ linkedin } weibo={ weibo } weiboImageLink={weiboImageLink} wechat={ wechat } wechatImageLink={wechatImageLink}/>
  </div>
)


export default ShareBox;

import React from "react"

interface Props {
  image: string
  description: string
  link: string
}
const Article = ({ image, description, link } : Props) => (
  <div className="article">
        <div className="article__box">
            <a className="article__image" href={ link } target="__blank" style={{ backgroundImage: `url(${image})`}}/>
            <div className="article__description" dangerouslySetInnerHTML={ { __html: description} }></div>
            <a href={ link } target="__blank" className="article__link link"><span>Read more</span></a>
        </div>
  </div>
)


export default Article;

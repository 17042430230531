import React, { useRef, useState, useEffect } from "react"
import debounce from '../utils/debounce';
import Plus from '../images/plus.inline.svg';

interface Props {
    title: string
    content: string
}
const AccordionItem = ({ title, content } : Props) => {
    const [active, setActive] = useState(false);
    const [height, setHeight] = useState("0px");
    
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        setActive(!active);
    }
    const onResize = debounce(() => {
        setHeight(active ? `${ contentRef.current.scrollHeight }px` : "0px");
    }, 200, false);

    useEffect(() => {
        setHeight(active ? `${ contentRef.current.scrollHeight }px` : "0px");
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', onResize, true);
        }

        return (() => {
            window.removeEventListener('resize', onResize, true);
        });
    },[active, contentRef]);
    return (
    <div className={ `accordion__item ${ active && 'accordion__item--active' }`}>
        <button type="button" className="accordion__title" onClick={ toggleAccordion }>{ title } <div className="accordion__icon"><Plus/></div></button>
        <div ref={ contentRef } style={{ maxHeight: `${ height }` }} className="accordion__content">
            <div className="accordion__text" dangerouslySetInnerHTML={ { __html: content} }/>
        </div>
    </div>
    );
}
type AccordionProps = {
    children: any
    className?: string
}
class Accordion extends React.Component<AccordionProps> {
    static Item = AccordionItem;
    render() {
        const { children, className } = this.props;
        return (         
            <div className={`accordion ${ className }`}>
                { children }
            </div>
        );
    }
}

export default Accordion;

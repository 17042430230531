module.exports = {
	siteMetadata: {
		title: `Adobe CQ | Discover your creative potential`,
		description: `Take the test`,
		siteUrl: 'https://www.adobecq.com',
		author: `@gatsbyjs`,
	},
	// pathPrefix: `/adobe-cq`,
	plugins: [
		`gatsby-plugin-sitemap`,
		`gatsby-plugin-react-helmet`,
		`gatsby-plugin-typescript`,
		`gatsby-plugin-sass`,
		// {
		// 	resolve: `gatsby-plugin-facebook-sdk`,
		// 	options: {
		// 	  appId: '569022204053221',
		// 	  status: true,
		// 	  xfbml: true,
		// 	  version    : 'v2.7'
		// 	},
		// },
		{
			resolve: `gatsby-plugin-stylelint`,
			options: { files: ["**/*.{js,jsx,tsx}"] },
		},
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				name: `images`,
				path: `${__dirname}/src/images`,
			},
		},
		{
			resolve: 'gatsby-plugin-react-svg',
			options: {
			  rule: {
				include: /\.inline\.svg$/
			  }
			}
		},{
			resolve: 'adobe-chinese-fonts',
			options: {
				kitId: 'dln8mpd'
			}
        },
        'adobe-gdpr',
		{
			resolve: 'adobe-facebook-pixel',
			options: {
				pixelId: '304889680643399'
			}
		},
		`gatsby-transformer-sharp`,
		`gatsby-plugin-sharp`,
		`gatsby-plugin-glslify`,
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: `gatsby-starter-default`,
				short_name: `starter`,
				start_url: `/`,
				background_color: `#D6D5C5`,
				theme_color: `#D6D5C5`,
				display: `minimal-ui`,
				icon: `static/favicons/favicon.png`, // This path is relative to the root of the site.
				icons: [
					{
						src: `static/favicons/android-chrome-192x192.png`,
						sizes: `192x192`,
						type: `image/png`,
					},
					{
						src: `/static/favicons/android-chrome-512x512.png`,
						sizes: `512x512`,
						type: `image/png`,
					},
				], // Add or remove icon sizes as desired
				// icon: `src/images/gatsby-icon.png`, // This path is relative to the root of the site.
			},
		},
		{
			resolve: `gatsby-plugin-google-analytics`,
			options: {
			  // replace "UA-XXXXXXXXX-X" with your own Tracking ID
			  trackingId: "UA-170476379-1",
			},
		},
		{
			resolve: `gatsby-plugin-linkedin-insight`,
			options: {
			  partnerId: `872`,
		
			  // Include LinkedIn Insight in development.
			  // Defaults to false meaning LinkedIn Insight will only be loaded in production.
			  includeInDevelopment: false
			}
		}
		// this (optional) plugin enables Progressive Web App + Offline functionality
		// To learn more, visit: https://gatsby.dev/offline
		// `gatsby-plugin-offline`,
	],
}

import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Article from '../components/article';
import Accordion from '../components/accordion';
import SocialShareList from '../components/social-share-list';
import ShareBox from '../components/share-box';
import Logo from '../images/adobe-logo.inline.svg';
import Loader from '../components/loader';
import {  isLinkedinApp, isIphone, isIpad, isWeChatApp } from '../utils/utilities';
import { Helmet } from "react-helmet"
import createjs from 'preload-js';
import { siteMetadata } from "../../gatsby-config";
import FooterPolicies from '../components/footer-policies';

interface Props {
    location: any
    pageContext: Context,
    data: any
}
type Context = {
    settings: any
    langCode: string
    lang: string
    translation: any
    route: string
    title: string
    description: string
    areaOfGreatestExpertise: string
    areaOfGreatestPotential: string
    socialMediaText: string
    articles: any[]    
    highRankArea: string
    lowRankArea: string
    persona: string
    slug: string
    expertiseImageDir: string
    expertiseSlug: string
    facebookImage: string
    linkedInImage: string
    twitterImage: string
}

export const query = graphql`query getFiles($expertiseImageDir: String, $expertiseSlug: String, $facebookImage: String, $linkedInImage: String ) {
    facebookImage: file( name: { eq: $facebookImage}) {
        publicURL
    }
    linkedInImage: file( name: { eq: $linkedInImage}) {
        publicURL
    }
    twitterImage: file( name: { eq: $linkedInImage}) {
        publicURL
    }
    keyVisual: file( name: { eq: $expertiseSlug }, extension: { eq: "png" }, dir: { regex: $expertiseImageDir }) {
        publicURL
    }
}`;

const ResultPage = ({ pageContext, data, location }: Props) => {
    const { translation, route, title, description, areaOfGreatestExpertise, areaOfGreatestPotential, socialMediaText, articles, highRankArea, lowRankArea, persona, slug, expertiseSlug, lang, langCode, settings } = pageContext;

    let regionLang = lang;
    if (regionLang === 'eu') {
        regionLang = 'en';
    }
    const facebookShareImage = `/socials/${regionLang}/${expertiseSlug}-facebook.png`;
    const linkedInShareImage = `/socials/${regionLang}/${expertiseSlug}-linkedin.png`;
    const twitterShareImage = `/socials/${regionLang}/${expertiseSlug}-twitter.png`;
    const weiboShareImage = `${siteMetadata.siteUrl}/socials/${regionLang}/${expertiseSlug}-weibo.png`;
    const wechatInShareImage = `${siteMetadata.siteUrl}/socials/${regionLang}/${expertiseSlug}-wechat.png`;
    
    const [progress, setProgress] = useState(0);

    const handleProgress = (data:any) => {
    }
    const handleComplete = (data:any) => {
        setProgress(1);        
    }
    useEffect(() => {
        setProgress(0.8);
        const preload = new createjs.LoadQueue();
        preload.loadFile(data.keyVisual.publicURL);
        // articles.forEach((article) => {
        //     let image = article.image;
        //     if (!image.includes('http')) {
        //         image = `/results/articles/${lang}/${article.image}`;
        //     }
        //     preload.loadFile(image);
        // });
        preload.addEventListener("progress", handleProgress);
        preload.addEventListener("complete", handleComplete);
        preload.load();
    },[])

    const FooterContent = () => (<>
        <FooterPolicies translation={translation}/>
        <a href={ translation.general.adobe_link } target="_blank" className="footer__logo" aria-label={ translation.general.got_to_adobe_website }><Logo/></a>
    </>);
    
    const handleTheClick = (zipURL:string, pdfURL: string) => {
        if ((isLinkedinApp && (isIphone || isIpad)) || isWeChatApp || lang === 'cn') {
            if (pdfURL) {
                window.open(pdfURL);
            }
            return false;
        } else {
            window.open(zipURL);
        }
    }
    
    return (
        <Layout pathname={ location.pathname} socialLinks={settings.social_media} lang={ lang } route={route} translation={translation} slug={slug} title={ title } socialMediaTitle={ title } socialMediaImageWeibo={ weiboShareImage } socialMediaImageWechat={ wechatInShareImage } socialMediaDescription={ socialMediaText } socialMediaImageFacebook={ facebookShareImage } socialMediaImageLinkedin={ linkedInShareImage } socialMediaImageTwitter={ twitterShareImage } about={ true } share={ false } footerContent={FooterContent}>
            <Helmet bodyAttributes={{
                class: `result ${ persona }  lang-${lang}`
            }}/>
                <SEO lang={langCode} title="CQ Test" />
                <div className={ `result-page` }>
                <Loader lang={lang}  progress={ progress } onComplete={ () => document.documentElement.classList.add('in') } />
                <div className="result-page__grid">                
                    <div className="result-page__right">
                        <img className="result-image-3d" src={ data.keyVisual.publicURL } alt={expertiseSlug}/>
                    </div>                    
                    <div className="result-page__left">
                        <div className="result-page__top-bar">
                            <button type="button" onClick={ () => handleTheClick(`/files/${regionLang}/${slug}.zip`, `/pdf/${regionLang}/${slug}.pdf` ) }  title={ translation.general.download_your_results } className="link link--lg link--underlined">{ translation.general.download_your_results }</button>
                            <SocialShareList title={ title } wechatImageLink={wechatInShareImage} weiboImageLink={weiboShareImage} description={ socialMediaText } {...settings.social_media }/>
                        </div>
                        <div className="result-page__info">
                            <h1 className="result-page__title">{ title }</h1>
                            <div className="result-page__description">
                                <table className="result-page__areas">
                                    <tbody>
                                        <tr>
                                            <th className="result-page__area"><b>{ translation.general.area_of_greatest_expertise }</b></th>
                                            <td><span className="result-page__divider"></span></td>
                                            <td><span className="result-page__area-text">{ highRankArea }</span></td>
                                        </tr>
                                        <tr>
                                            <th className="result-page__area"><b>{ translation.general.area_of_greatest_potential}</b></th>
                                            <td><span className="result-page__divider"></span></td>
                                            <td><span className="result-page__area-text">{ lowRankArea }</span></td>
                                        </tr>
                                        </tbody>
                                </table>
                                <div dangerouslySetInnerHTML={{ __html: description}}/>
                            </div>
                        </div>
                        <Accordion className="result-page__accordion">
                            <Accordion.Item key="accordion-1" title={ translation.general.area_of_greatest_expertise } content={ areaOfGreatestExpertise } />
                            <Accordion.Item key="accordion-2" title={ translation.general.area_of_greatest_potential } content={ areaOfGreatestPotential } />
                        </Accordion>
                        { articles.length > 0 && 
                            <div className="articles">
                                <div className="articles__heading">{ translation.general.recommended_articles }</div>
                                { articles.map((article, index) => {
                                    let image = article.image;
                                    if (!article.image.includes('http')) {
                                        image = `/results/articles/${lang}/${article.image}`;
                                    }

                                    return (
                                        <Article key={ `article-${index}`}image={ image } description={ article.description } link={ article.link }/>)
                                })}
                            </div>
                        }
                        <ShareBox title={ translation.general.share_your_cq_using } weiboImageLink={weiboShareImage} wechatImageLink={wechatInShareImage} social_title={ title } description={ socialMediaText } image={ facebookShareImage }  {...settings.social_media }/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ResultPage
